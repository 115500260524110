<template>
  <div class="wiki-details fill-height" v-if="data">
    <BackBtn class="wiki-details__back" />
    <div class="wiki-details-inner--large">
      <ArticleHeader :data="data.header" />
      <div class="wiki-content">
        <div class="wiki-content-inner">
          <div class="flexible">
            <div
              v-for="(cpt, index) in data.flexibleContent"
              :key="`fl-${index}`"
              :class="`fl-` + cpt.component"
            >
              <component
                v-if="cpt.component && availableFlexibles[cpt.component]"
                :is="availableFlexibles[cpt.component]"
                :data="cpt.content"
                :class="[cpt.component]"
                :key="data.header.title"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Error />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { single } from '@/guides/endpoints/wiki'
import { useRoute } from 'vue-router'
import BackBtn from '@/components/BackBtn.vue'
import DropdownList from '@/components/DropdownList.vue'
import Error from '@/components/Error.vue'
import ArticleHeader from '@/components/article/Header.vue'
import Wysiwyg from '@/components/Wysiwyg.vue'

const availableFlexibles = {
  dropdown: DropdownList,
  wysiwyg: Wysiwyg,
}

export default defineComponent({
  name: 'wikiDetails',
  components: {
    ArticleHeader,
    BackBtn,
    DropdownList,
    Error,
    Wysiwyg,
  },
  setup() {
    const data = ref()
    const route = useRoute()
    const slug = computed(() => route?.params?.slug)

    const fetch = () => {
      if (!slug.value) {
        return
      }

      single(slug.value).then(r => {
        data.value = r.data.content
      })
    }

    watch(() => slug.value, fetch)

    fetch()

    return {
      availableFlexibles,
      data,
    }
  },
})
</script>

<style lang="scss" scoped>
.wiki-details {
  @include details-intro;

  position: relative;
  background-color: $c-white;
}

[class*='wiki-details-inner--'],
.wiki-details-inner {
  position: relative;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  padding: $spacing * 3 0;
  z-index: 0;

  @include mq(m) {
    width: calc(100% - #{$spacing * 4});
  }

  &[class*='--large'] {
    max-width: 60rem;
    @include mq(m) {
      max-width: 72rem;
    }
  }
}

[class*='wiki-content-inner--'],
.wiki-content-inner {
  margin: 0 $spacing * 1.35;
  @include mq(m) {
    margin: 0;
  }
}

[class*='fl-'] {
  margin-bottom: $spacing;
}
</style>
